
import { defineComponent } from 'vue';
import { useRoute, useRouter, } from 'vue-router';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButton, IonRippleEffect, IonText } from '@ionic/vue';
import { get } from 'lodash';
import AudioPlayer from '@/components/AudioPlayer.vue';

export default defineComponent({

  name: 'Exhibition',

  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonRippleEffect,
    IonText,
    AudioPlayer,
  },

  data() {
    return {
      exhibition: null as any,
      activeGuide: null as any,
    };
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    return { router, route };
  },

  mounted() {
    this.getExhibition(this.route.params.slug as string);
  },

  computed: {
    activeItem(): any {
      if (this.exhibition) {
        if (this.route.params.items && this.route.params.items.length > 0) {
          const items = this.route.params.items as Array<string>;
          const path = items.flatMap(item => ['children', item]).slice(1);

          return get(this.exhibition.guides, path);
        }

        return { children: this.exhibition.guides } as any;
      }

      return null;
    }
  },

  methods: {
    getExhibition(slug: string) {
      return this.$http.get(`/api/exhibitions/${slug}`).then((response) => {
        this.exhibition = response.data.data;

        if (this.exhibition.color) {
          this.$nextTick(() => { // Not sure why $refs not available without $nextTick
            (this.$refs.content as any).$el.style.setProperty('--background', this.exhibition.color);
          });
        }

        return response.data;
      })
    },

    activateItem(key: string, item: any) {
      if (item.type === 'audio-guide' && item.audio) {
        if (this.activeGuide === item) {
          this.activeGuide = null;
        } else {
          this.activeGuide = item;
        }
      } else {
        const prevItems = this.route.params.items ? this.route.params.items : [];
        this.router.push({ name: 'Exhibition', params: { items: [ ...prevItems, key ] } });
      }
    }
  },

});
