import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createBlock(_component_ion_footer, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, {
        mode: "md",
        color: "light"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_progress_bar, { value: _ctx.progress }, null, 8, ["value"]),
          _createVNode(_component_ion_icon, { name: _ctx.refresh }, null, 8, ["name"]),
          _createVNode(_component_ion_title, { slot: "start" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.playerSeek(-15))),
                disabled: _ctx.isLoading
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.playBackOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_ion_fab_button, { onClick: _ctx.togglePlay }, {
                default: _withCtx(() => [
                  (_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_ion_spinner, {
                        key: 0,
                        name: "crescent"
                      }))
                    : (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 1,
                        icon: _ctx.isPlaying ? _ctx.pause : _ctx.play
                      }, null, 8, ["icon"]))
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.playerSeek(30))),
                disabled: _ctx.isLoading
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.playForwardOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}