
import { IonFooter, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonProgressBar, IonFabButton, IonSpinner } from '@ionic/vue';
import { refresh, play, pause, playBackOutline, playForwardOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { Howl } from 'howler';

export default defineComponent({

  name: 'AudioPlayer',

  components: {
    IonFooter,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonProgressBar,
    IonFabButton,
    IonSpinner,
  },

  props: {
    url: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    autoplay: {
      type: Boolean,
      default: false,
    },
  },

  setup () {
    return { refresh, play, pause, playBackOutline, playForwardOutline };
  },

  data() {
    return {
      player: null as Howl|null,
      isPlaying: false,
      isLoading: false,
      progress: 0 as number, // TODO computed prop?
    };
  },

  watch: {
    url: {
      immediate: true,
      handler(url) {
        if (this.player) {
          this.player.stop();
          this.player.unload();
        }
        this.player = this.initPlayer(url);
      }
    }
  },

  beforeUnmount () {
    if (this.player) {
      this.player.stop();
      this.player.unload();
    }
  },

  methods: {
    initPlayer(url: string) {
      this.isLoading = true;
      const player = new Howl({
        src: [url],
        html5: true,
        autoplay: this.autoplay,
        onplay: () => {
          this.isPlaying = true;
          requestAnimationFrame(this.updateProgress);
        },
        onpause: () => { this.isPlaying = false; },
        onstop: () => { this.isPlaying = false; },
        onend: () => {
          this.isPlaying = false;
          this.progress = 0;
        },
        onseek: () => {
          requestAnimationFrame(this.updateProgress);
        },
        onload: () => {
          this.isLoading = false;
        },
        onloaderror: () => {
          this.isLoading = false;
        },
      });

      return player;
    },

    togglePlay() {
      if (this.player) {
        this.isPlaying ? this.player.pause() : this.player.play();
      }
    },

    playerSeek(sec: number) {
      if (this.player) {
        const currentPos = this.player.seek();
        this.player.seek(currentPos + sec);
      }
    },

    updateProgress() {
      if (this.player) {
        const seek = this.player.seek() || 0;

        this.progress = (((seek / this.player.duration())) || 0);

        if (this.player.playing()) {
          requestAnimationFrame(this.updateProgress);
        }
      }
    },
  },
});
