import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22951cf5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt", "title"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["src", "alt", "title"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_audio_player = _resolveComponent("audio-player")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { ref: "content" }, {
        default: _withCtx(() => [
          (_ctx.exhibition)
            ? (_openBlock(), _createBlock(_component_ion_grid, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        "size-xs": "10",
                        "size-sm": "8",
                        "size-md": "6"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.route.params.items && _ctx.route.params.items.length > 0)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "img-btn ion-activatable",
                                onClick: _cache[0] || (_cache[0] = () => _ctx.router.push({ name: 'Exhibition', params: { items: _ctx.route.params.items.slice(0, -1) } }))
                              }, [
                                (_ctx.activeItem.image)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: _ctx.activeItem.image,
                                      alt: _ctx.activeItem.title,
                                      title: _ctx.activeItem.title
                                    }, null, 8, _hoisted_1))
                                  : (_openBlock(), _createBlock(_component_ion_text, {
                                      key: 1,
                                      class: "ion-text-center"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h1", null, _toDisplayString(_ctx.activeItem.title), 1)
                                      ]),
                                      _: 1
                                    })),
                                _createVNode(_component_ion_ripple_effect)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                (_ctx.exhibition.image)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: _ctx.exhibition.image,
                                      alt: _ctx.exhibition.title,
                                      title: _ctx.exhibition.title
                                    }, null, 8, _hoisted_3))
                                  : _createCommentVNode("", true)
                              ]))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.activeItem)
                    ? (_openBlock(), _createBlock(_component_ion_row, {
                        key: 0,
                        class: "ion-align-items-center"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeItem.children, (item, key) => {
                            return (_openBlock(), _createBlock(_component_ion_col, {
                              key: item.key,
                              size: _ctx.activeItem.columns ? (12 /_ctx.activeItem.columns) : 12
                            }, {
                              default: _withCtx(() => [
                                (item.image)
                                  ? (_openBlock(), _createElementBlock("button", {
                                      key: 0,
                                      class: "img-btn ion-activatable",
                                      onClick: ($event: any) => (_ctx.activateItem(key, item))
                                    }, [
                                      _createElementVNode("img", {
                                        src: item.image
                                      }, null, 8, _hoisted_5),
                                      _createVNode(_component_ion_ripple_effect)
                                    ], 8, _hoisted_4))
                                  : (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 1,
                                      expand: "full",
                                      fill: "solid",
                                      size: "large",
                                      color: "light",
                                      strong: "",
                                      onClick: ($event: any) => (_ctx.activateItem(key, item))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.title), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                              ]),
                              _: 2
                            }, 1032, ["size"]))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512),
      (_ctx.activeGuide)
        ? (_openBlock(), _createBlock(_component_audio_player, {
            key: 0,
            url: _ctx.activeGuide.audio,
            title: _ctx.activeGuide.title,
            autoplay: ""
          }, null, 8, ["url", "title"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}